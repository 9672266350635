const StatusGroHierarquiasElaboracaoEnum = Object.freeze({
    SEM_APLICACAO: 'SEM APLICAÇÃO',
    PENDENTE: 'PENDENTE',
    VALIDACAO_EM_ANDAMENTO: 'VALIDAÇÃO EM ANDAMENTO',
    PENDENTE_AVALIADOR: 'PENDENTE AVALIADOR',
    VALIDADO: 'VALIDADO',
    INATIVADO: 'INATIVADO',
    CONCLUIDO: 'CONCLUÍDO'
});

export default StatusGroHierarquiasElaboracaoEnum;
