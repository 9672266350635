<script setup>
import { defineProps, defineEmits, computed, ref, onBeforeMount } from 'vue';
import SesmtService from '@/services/SesmtService';

const service = ref();

const value = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value)
});

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
    branchId: String,
    customerId: Number,
    modelValue: {
        type: Object
    },
    recordsPerPage: {
        type: Number,
        default: 20
    },
    optionValue: {
        type: String
    },
    filtrosExtras: {
        type: Object
    }
});
onBeforeMount(
    () =>
        (service.value = new SesmtService(`/customer/departments/combo-setores-com-hierarquia/cliente/${props.customerId}/unidade/${props.branchId}`))
);
</script>
<template>
    <AppInfinityDropdown
        ref="infintyDropdown"
        v-model="value"
        optionLabel="name"
        :filterFields="['name']"
        :service="service"
        :recordsPerPage="recordsPerPage"
        :filtrosExtras="filtrosExtras"
    />
</template>
