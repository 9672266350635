<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { useMoment } from '../../../../composables/useMoment';
import StatusGrid from '../StatusGrid.vue';
import { GrupoRiscosLabelEnum } from '../../../../enums/GrupoRiscos';
import DialogExcluirExposicao from './DialogExcluirExposicao.vue';
const emit = defineEmits(['onSuccessExcluirExposicao']);
const props = defineProps({
    loading: {
        type: Boolean,
        required: true
    },
    respostasPerigos: {
        type: Array
    },
    inventarioHierarquiaId: {
        type: Number
    },
    statusGrupoRiscosElaboracaoOpcoes: {
        type: Array,
        required: true
    },
    statusCaracterizacaoRiscoOpcoes: {
        type: Array,
        required: true
    },
    hierarquia: {
        type: Object
    }
});
const riscosColunas = [
    {
        field: 'grupo',
        header: 'Grupo'
    },
    {
        field: 'risco',
        header: 'Risco',
        style: 'font-weight: bold'
    },
    {
        field: 'matrizConfigLinha',
        header: 'Nível de risco',
        style: 'font-weight: bold'
    },
    {
        field: 'medicao',
        header: 'Medição'
    },
    {
        field: 'caracterizacao',
        header: 'Caracterização'
    },

    {
        field: 'descricaoExposicao'
    },
    {
        field: 'aplicacaoAtividadeEspecifica'
    },
    {
        field: 'acoes',
        style: 'width: 55px'
    }
];
const { formatDateTz } = useMoment();
const { currentRoute, push } = useRouter();
const dialogExcluirExposicao = ref();
const labelEnumRisco = GrupoRiscosLabelEnum;
const openDialogExcluirExposicao = (data, indexHierarquia) => dialogExcluirExposicao.value.openDialog(data, indexHierarquia);
const menu = ref({});
const linhaAtual = ref(null);
const indexAtual = ref(null);
const toggleMenu = (event, data, index) => {
    linhaAtual.value = data;
    indexAtual.value = index;
    menu.value?.toggle(event);
};

const actionItems = [
    {
        label: 'Acessar caracterização',
        icon: 'pi pi-file',
        disabled: () => {},
        command: () => {
            redirectCaracterizacao(linhaAtual.value.id);
        }
    },

    {
        label: 'Excluir',
        icon: 'pi pi-trash',
        disabled: () => {},
        command: () => openDialogExcluirExposicao({ id: linhaAtual.value.id, hierarquiaRecebida: props.hierarquia }, indexAtual)
    }
];

const redirectCaracterizacao = (id) => {
    const setor = props.hierarquia?.hierarquia.customerDepartment.name;
    const cargo = props.hierarquia?.hierarquia.customerPosition.name;
    const basePath = currentRoute.value.path.endsWith('/') ? currentRoute.value.path : currentRoute.value.path + '/';
    if (!id) {
        return push({ path: `${basePath}caracterizacao` });
    }
    return push({ path: `${basePath}caracterizacao/${id}/setor/${setor}/cargo/${cargo}` });
};

const getStatusGrupoRiscosComTodasAsConfigs = (status) => props.statusGrupoRiscosElaboracaoOpcoes.find((p) => p.label === status);
const getStatusCaracterizacaoRiscoComTodasAsConfigs = (status) => props.statusCaracterizacaoRiscoOpcoes.find((p) => p.label === status);
const descricaoDataExposicao = ({ dataInicio, dataFim, exposicaoAtiva }) => {
    const incio = formatDateTz(dataInicio);
    const fim = exposicaoAtiva ? 'Exposição ativa' : `Fim: ${formatDateTz(dataFim)}`;

    return `Início:${incio} \n${fim}`;
};

const onSuccessExcluirExposicao = (eventHierarquia) => {
    emit('onSuccessExcluirExposicao', eventHierarquia);
};
</script>
<template>
    <DataTable dataKey="id" :loading="loading" :value="respostasPerigos" size="small">
        <template #empty> {{ !inventarioHierarquiaId ? 'Sem aplicação.' : 'Ausência de risco.' }}</template>
        <template #loading>
            <AppLoadingWrapper />
        </template>

        <Column v-for="col of riscosColunas" :field="col.field" :key="col.field" :style="col.style">
            <template #header>
                <div class="font-semibold">{{ col.header }}</div>
            </template>

            <template #body="{ data, index }">
                <template v-if="col.field === 'acoes'">
                    <Button class="p-button-text p-button-secondary" icon="pi pi-ellipsis-v" @click="toggleMenu($event, data, index)" />
                </template>

                <template v-else-if="col.field === 'grupo'">
                    <Tag
                        :style="getStatusGrupoRiscosComTodasAsConfigs(labelEnumRisco.get(data.risco.grupoRisco))?.style"
                        :value="getStatusGrupoRiscosComTodasAsConfigs(labelEnumRisco.get(data.risco.grupoRisco))?.label"
                        class="text-lg"
                    />
                </template>
                <template v-else-if="col.field === 'caracterizacao'">
                    <StatusGrid :status="getStatusCaracterizacaoRiscoComTodasAsConfigs(data.statusCaracterizacao)" />
                </template>

                <template v-else-if="col.field === 'matrizConfigLinha'">
                    {{ data?.matrizConfigLinhaEixoRId ? data.matrizConfigLinhaEixoR.titulo : 'Sem informação' }}
                </template>

                <template v-else-if="col.field === 'risco'">
                    {{ data[col.field].nome }}
                </template>
                <template v-else-if="col.field === 'medicao'">
                    <CustomMedicaoLabel :data="data" />
                </template>

                <template v-else-if="col.field === 'descricaoExposicao'">
                    <i
                        v-tooltip.top="
                            descricaoDataExposicao({
                                dataInicio: data.dataInicio,
                                dataFim: data?.dataFim,
                                exposicaoAtiva: data?.exposicaoAtiva
                            })
                        "
                        class="pi pi-info-circle text-2xl"
                    ></i>
                </template>

                <template v-else-if="col.field === 'aplicacaoAtividadeEspecifica'">
                    <i
                        v-if="data[col.field]"
                        v-tooltip.top="`Atividade especifica: ${data[col.field]?.atividadeEspecifica?.nome}`"
                        class="pi pi-exclamation-triangle text-orange-400 text-2xl"
                    ></i>
                </template>
                <template v-else>
                    <span>{{ data[col.field] }}</span>
                </template>
            </template>
        </Column>
    </DataTable>
    <Menu ref="menu" :model="actionItems" :popup="true" />
    <DialogExcluirExposicao ref="dialogExcluirExposicao" @onSuccessExcluirExposicao="onSuccessExcluirExposicao" />
</template>
<style lang="scss" scoped>
.valor-alerta {
    border: 2px solid #d06900;
    width: fit-content;
    border-radius: 20px;
}
</style>
