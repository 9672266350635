<script setup>
import { ref, defineExpose } from 'vue';
import { checkPermission } from '@/common/check-permission';
import { useAlert } from '../../../composables/useAlert';
import { useRouter } from 'vue-router';

const record = ref(null);
const aberto = ref(false);
const fechar = () => (aberto.value = false);
const router = useRouter();
const { alertInfo } = useAlert();

const abrir = async (data) => {
    record.value = data;
    aberto.value = true;
};

const irParaSetor = () => {
    if (!checkPermission('estrutura_setor:editar')) {
        alertInfo('Você não tem permissão para editar o setor!');
        return;
    }
    const routeUrl = router.resolve({ path: `/department/${record.value.setorId}` }).href;
    window.open(routeUrl, '_blank');
};

const irParaCargo = () => {
    if (!checkPermission('estrutura_cargo:editar')) {
        alertInfo('Você não tem permissão para editar o cargo!');
        return;
    }
    const routeUrl = router.resolve({ path: `/position/${record.value.cargoId}` }).href;
    window.open(routeUrl, '_blank');
};

const irParaHierarquia = () => {
    if (!checkPermission('estrutura_hierarquia:editar')) {
        alertInfo('Você não tem permissão para editar a hierarquia!');
        return;
    }
    const routeUrl = router.resolve({ path: `/hierarchy/${record.value.hierarquiaId}` }).href;
    window.open(routeUrl, '_blank');
};

defineExpose({
    abrir
});
</script>
<template>
    <Dialog v-model:visible="aberto" :style="{ width: '40rem' }" header="Detalhes" @hide="fechar" :modal="true">
        <template #default>
            <div class="mb-4">
                <div class="flex flex-row justify-content-between align-items-center">
                    <h4>Setor</h4>
                    <Button icon="pi pi-pencil" severity="secondary" text rounded aria-label="Filter" class="p-button-lg" @click="irParaSetor()" />
                </div>
                <span v-if="record.setorDescricao.trim()">{{ record.setorDescricao }}</span>
                <Message v-else severity="warn" icon="pi pi-exclamation-circle" :closable="false">Pendente de preenchimento</Message>
            </div>
            <div class="mb-4">
                <div class="flex flex-row justify-content-between align-items-center">
                    <h4>Cargo</h4>
                    <Button icon="pi pi-pencil" severity="secondary" text rounded aria-label="Filter" class="p-button-lg" @click="irParaCargo()" />
                </div>
                <span v-if="record.cargoDescricao.trim()">{{ record.cargoDescricao }}</span>
                <Message v-else severity="warn" icon="pi pi-exclamation-circle" :closable="false">Pendente de preenchimento</Message>
            </div>
            <div class="mb-4">
                <div class="flex flex-row justify-content-between align-items-center">
                    <h4>Hierarquia</h4>
                    <Button
                        icon="pi pi-pencil"
                        severity="secondary"
                        text
                        rounded
                        aria-label="Filter"
                        class="p-button-lg"
                        @click="irParaHierarquia()"
                    />
                </div>
                <span v-if="record.hierarquiaCargoDescricao.trim()">{{ record.hierarquiaCargoDescricao }}</span>
                <Message v-else severity="warn" icon="pi pi-exclamation-circle" :closable="false">Pendente de preenchimento</Message>
            </div>
        </template>
    </Dialog>
</template>
