<script setup>
import { ref, defineExpose } from 'vue';
import TagDescricaoExposicao from './TagDescricaoExposicao.vue';
import { getClientSesmt } from '../../../../services/http-sesmt';
import { useAlert } from '../../../../composables/useAlert';

const { alertError } = useAlert();

const isOpen = ref(false);
const setor = ref();
const cargo = ref();
const customerDescription = ref();
const records = ref([]);
const loading = ref(false);
const page = ref(1);
const recordsPerPage = ref(10);
const totalRecords = ref(0);
const totalPages = ref(0);
const filter = ref(null);
const hierarquiaRecebida = ref();
const sort = ref(null);
const currentSortOrder = ref(1);

const load = async () => {
    try {
        loading.value = true;
        const hierarquiaId = hierarquiaRecebida.value?.hierarquia.id;
        const inventarioHierarquiaId = hierarquiaRecebida.value?.inventarioHierarquiaId ?? 0;

        const { data } = await getClientSesmt().get(
            `/aplicacao-pgr-elaboracao/find-functionarios-e-riscos-aplicados/hierarquia/${hierarquiaId}/inventario-hierarquia/${inventarioHierarquiaId}`,
            {
                params: {
                    limit: recordsPerPage.value,
                    page: page.value,
                    filter: filter.value,
                    sort: sort.value
                }
            }
        );

        const temFuncionariosERiscos = data?.items?.filter((item) => item?.hierarquia?.customerEmployee.length > 0);

        if (temFuncionariosERiscos.length) {
            records.value = data.items.flatMap((item) =>
                item.hierarquia.customerEmployee.map((employee) => {
                    return {
                        ...employee,
                        aplicacaoRespostaPerigo: item.aplicacaoRespostaPerigo
                    };
                })
            );
        } else {
            records.value = data.items;
        }

        totalRecords.value = data.meta?.totalItems;
        totalPages.value = data.meta?.totalPages;
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loading.value = false;
    }
};

const onSort = async (event) => {
    currentSortOrder.value = event.sortOrder;

    const field = event.sortField
        ?.split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();

    const order = event.sortOrder == 1 ? `ASC` : 'DESC';
    if (field) {
        sort.value = {
            [field]: order
        };
    } else {
        sort.value = null;
    }

    await load();
};

const onPage = async (event) => {
    page.value = event.page + 1;
    recordsPerPage.value = event.rows;
    await load();
};

const openDialog = async ({ hierarquia, customer }) => {
    if (!hierarquia || !customer) return;
    hierarquiaRecebida.value = hierarquia;
    customerDescription.value = customer;
    setor.value = hierarquia.hierarquia.customerDepartment.name;
    cargo.value = hierarquia.hierarquia.customerPosition.name;

    await load();

    isOpen.value = true;
};

const removerFiltros = async () => {
    filter.value = null;
    await load();
};

const closeDialog = () => (isOpen.value = false);

const verificarFuncionarioVinculado = (funcionarioId, aplicacaoAtividadesEspecificasFuncionarios) =>
    aplicacaoAtividadesEspecificasFuncionarios?.some((item) => item.funcionarioId === funcionarioId);

const validarAtividadeEspecifica = (funcionarioId, data) => {
    if (!data?.length) return;

    const atividade = data.find(
        (item) =>
            item?.aplicacaoAtividadeEspecificaId &&
            verificarFuncionarioVinculado(funcionarioId, item?.aplicacaoAtividadeEspecifica?.aplicacaoAtividadesEspecificasFuncionarios)
    );

    return atividade?.aplicacaoAtividadeEspecificaId;
};
defineExpose({
    openDialog
});
</script>
<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '80%' }" header="Funcionários x Riscos" @hide="closeDialog" :draggable="false" modal>
        <div class="flex p-2 flex-row align-items-center gap-2 identificacao-customer">
            <i class="pi pi-building text-4xl"></i>
            <div class="flex flex-column">
                <span
                    class="font-semibold line-height-3 cursor-pointer nome-cliente"
                    v-tooltip.bottom="hierarquiaRecebida.hierarquia.customerBranch?.name"
                    >{{ hierarquiaRecebida.hierarquia.customerBranch?.name }}</span
                >
                <span class="">Cód. SOC - {{ customerDescription?.code }}</span>
            </div>
            <div class="descricoes p-2 flex flex-column">
                <div class="setor">
                    <span class="font-semibold line-height-3 cursor-pointer nome-cliente">Setor</span>
                    <span class="ml-2"> {{ setor }}</span>
                </div>
                <div class="cargo">
                    <span class="font-semibold line-height-3 cursor-pointer nome-cliente">Cargo</span>
                    <span class="ml-2"> {{ cargo }}</span>
                </div>
            </div>
        </div>
        <div class="flex align-items-end w-full mt-5 mb-3">
            <div class="flex flex-column w-full">
                <label for="setor" class="mb-2">Funcionários</label>
                <InputText @keyup.enter="load" v-model="filter" id="setor" class="w-full" />
            </div>
            <div class="flex w-full align-items-end justify-content-end">
                <div class="w-full">
                    <Button label="Remover filtros" icon="pi pi-filter-slash" class="p-button-link" @click="removerFiltros" />
                </div>
            </div>
        </div>

        <DataTable
            @sort="onSort"
            sortField="hierarquia"
            :sortOrder="currentSortOrder"
            dataKey="id"
            :value="records"
            responsiveLayout="stack"
            breakpoint="640px"
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column :sortable="true" field="name" sortField="customer_employee.name" header="Funcionário">
                <template #body="{ data }">
                    {{ data.name }}
                </template>
            </Column>
            <Column field="aplicacaoRespostaPerigo" style="width: 50px">
                <template #body="{ data }">
                    <div class="w-full flex justify-content-center align-items-center">
                        <i
                            v-if="validarAtividadeEspecifica(data?.id, data?.aplicacaoRespostaPerigo)"
                            class="pi pi-exclamation-triangle"
                            style="color: #d06900; font-size: 1.5rem"
                        ></i>
                    </div>
                </template>
            </Column>
            <Column field="aplicacaoRespostaPerigo" header="Exposição">
                <template #body="{ data }">
                    <div class="flex flex-wrap gap-2" v-if="data?.aplicacaoRespostaPerigo?.length">
                        <template :key="item.id" v-for="item of data.aplicacaoRespostaPerigo">
                            <TagDescricaoExposicao
                                v-if="
                                    verificarFuncionarioVinculado(
                                        data?.id,
                                        item?.aplicacaoAtividadeEspecifica?.aplicacaoAtividadesEspecificasFuncionarios
                                    ) || !item?.aplicacaoAtividadeEspecificaId
                                "
                                :descricaoRisco="item.risco.nome"
                                :nivelRisco="item.matrizConfigLinhaEixoR?.titulo"
                                :atividade="item.aplicacaoAtividadeEspecifica"
                            />
                        </template>
                    </div>
                </template>
            </Column>
        </DataTable>
        <Paginator
            v-show="!loading"
            :rows="recordsPerPage"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="[10, 50, 100]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            @page="onPage"
        ></Paginator>
    </Dialog>
</template>
<style lang="scss" scoped>
.identificacao-customer {
    padding: 1rem;
    border: solid #ededed 1px;
    border-radius: 4px;
}
.descricoes {
    border-left: solid #ededed 1px;
}
.nome-cliente {
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
