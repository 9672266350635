import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function useFiltrosInventarioRisco(props, emit) {
    const router = useRouter();
    const store = useStore();

    const _filtrosExtras = computed({
        get() {
            return props.filtrosExtras;
        },
        set(value) {
            emit('update:filtrosExtras', value);
        }
    });

    async function filtrar() {
        await atualizarQueryParams();
        emit('loadInventarioRisco');
    }

    async function atualizarQueryParams() {
        const { setor, cargo, statusGro, mostrarPendencias } = _filtrosExtras.value;
        store.dispatch('setQueryParamsTelaInventarioRiscoGro', {
            setorId: setor?.id ? JSON.stringify(setor?.id) : undefined,
            cargoId: cargo?.id ? JSON.stringify(cargo?.id) : undefined,
            statusGro,
            mostrarPendencias: mostrarPendencias ? Boolean(mostrarPendencias) : undefined
        });
        await router.replace({
            query: {
                setorId: setor?.id ? JSON.stringify(setor?.id) : undefined,
                cargoId: cargo?.id ? JSON.stringify(cargo?.id) : undefined,
                statusGro,
                mostrarPendencias: mostrarPendencias ? Boolean(mostrarPendencias) : undefined
            }
        });
    }

    async function removerFiltros() {
        _filtrosExtras.value = {};
        await router.replace();
        emit('loadInventarioRisco', true);
    }

    return {
        _filtrosExtras,
        filtrar,
        removerFiltros
    };
}
